html,body { 
  overflow-x: hidden !important; 
}
.App {
  min-width: 100vw;
}
.homepage, .App {
  min-height: 100vh;
}
.brief {
  margin-bottom: 100px;
}
.timelineheader, .pagedown {
  text-align: center;
}
/* .timeline-link {
  margin: 50px !important;
  padding: 50px !important;
  margin-bottom: 100px !important;
} */
.chartz {
  padding: 50px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.barchart {
  min-height: 500px;
  /* min-width: 600px; */
  /* min-zoom: 400px; */
}

.piechart {
  display: flex;
  flex-direction: column;
}
.barchart {
  display: none;
}

.piechart {
  /* width: 200px !important; */
  /* transform: rotate(90deg); */
  display: flex !important;
  flex-direction: row !important;;
}


.heading  {
  background-image: url("./war.png");
  background-size: auto 68%;
  background-position: center;
  background-repeat: no-repeat;
  height: 25.7rem;
  background-color: rgb(44,42,37);
  color: white;
}
.heading h1{
  font-family: "Ermilov", sans-serif;
  color: white;
  text-transform: uppercase;
  font-size: 2.9rem;
  font-weight: 700;
}
.rc-card-title {
  color: green !important;
}
.timelinestyle__Wrapper-sc-cif21b-0 {
  background-color: rgb(240, 240, 240) !important;
}
.rc-card-subtitle {
  color: rgb(97,115,156) !important;
}
.rc-card-text a{
  text-decoration: none !important;
  /* color: yellow !important; */
}
p .timeline-link {
  text-decoration: none !important;
  /* color: yellow !important; */
}


.rc-card-text a:hover {
  color: #18272F;
  /* position: relative; */
  text-decoration: underline;
}

.controls {
  display: none;
}
.sad404 {
  width: 250px;
}
.vertical-timeline::before {
  background-color: #2096F3 !important;
}
.pagedown {
  cursor: pointer;
}
/* .vertical-timeline-element-date {
  font-size: 40px !important;
} */
.vertical-timeline--animate, .timeline-container-div {
  font-family: "Ermilov", sans-serif;
  background-color: #E3E3E3;
}
.city-and-count {
  /* color: #000; */
  font-family: "Ermilov", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
}

.vertical-timeline-element-subtitle {
  font-weight: 800;
  color: #60729C;
}


.missing-data {
  color: red;
}
.pening-data {
  color: rgb(255, 111, 0) !important;
}

.pex {
  color: rgb(252, 178, 122);
}
.Occupied-tag, .Liberated-tag {
  color: white !important;
}
.about-us {
  margin-top: 50px;
}
/* .timeline-sub {
  background-color: #4CB1CD !important;
} */
div.color-coding {
  display: flex;
  justify-content: center;
  background-color: #E3E3E3;
  margin-bottom: 10px;
}

span.liberated {
  background-color: #4CB1CD;
  /* border-radius: 5px; */
  padding: 0 15px;
  color: white;
  font-weight: bold;
}
span.occupied {
  background-color: #ED753E;
  /* border-radius: 5px; */
  padding: 0 15px;
  color: white;
  font-weight: bold;
}
.history {
  background-color: rgb(253, 253, 253);
  /* margin-bottom: 50px; */
  color: black; 
}
.presidents-disclosure {
  padding: 15px;
  margin-bottom: 25px;
}